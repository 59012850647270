import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class ChannelProgram extends GenericDropdownOption<number> {
  channelId: string;
  companyId: number;
  integrationTypeId: number;

  constructor(id: number, channelId: string, companyId: number, integrationTypeId: number, companyName: string) {
    super(id, companyName, true);
    this.channelId = channelId;
    this.companyId = companyId;
    this.integrationTypeId = integrationTypeId;
  }
}
